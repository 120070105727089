import React, { useEffect, useRef, useState } from 'react';

import { default as MissionIcon1 } from 'assets/img/MissionIcon1.svg';
import { default as MissionIcon2 } from 'assets/img/MissionIcon2.svg';
import { default as MissionIcon3 } from 'assets/img/MissionIcon3.svg';
import { default as MissionIcon4 } from 'assets/img/MissionIcon4.svg';
import { default as MissionIcon5 } from 'assets/img/MissionIcon5.svg';
import Container from 'index.style';
import {
  Card,
  CardsGrid,
  CardText,
  CardTitle,
  ContainerMission,
  IconWrapper,
  Subtitle,
  Title,
} from './MissionBlock.style';
import { useTranslation } from 'react-i18next';

export const MissionBlock = () => {
  const { t } = useTranslation();
  const firstCardRef = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartAnimation(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      },
    );

    if (firstCardRef.current) {
      observer.observe(firstCardRef.current);
    }

    return () => {
      if (firstCardRef.current) {
        observer.unobserve(firstCardRef.current);
      }
    };
  }, []);

  return (
    <Container>
      <ContainerMission>
        <Title>{t('missionBlock.title')}</Title>
        <Subtitle>{t('missionBlock.subtitle')}</Subtitle>
        <CardsGrid>
          {/* К первому элементу добавляем ref для отслеживания появления на экране */}
          <Card ref={firstCardRef} className={`first ${startAnimation ? 'animate' : ''}`}>
            <IconWrapper>
              <img src={MissionIcon2} alt='iconMission' />
            </IconWrapper>
            <CardTitle>{t('missionBlock.cardTitle1')}</CardTitle>
            <CardText>{t('missionBlock.cardText1')}</CardText>
          </Card>

          <Card className={`second ${startAnimation ? 'animate' : ''}`}>
            <IconWrapper>
              <img src={MissionIcon5} alt='iconMission' />
            </IconWrapper>
            <CardTitle> {t('missionBlock.cardTitle2')}</CardTitle>
            <CardText>{t('missionBlock.cardText2')}</CardText>
          </Card>

          <Card className={`third ${startAnimation ? 'animate' : ''}`}>
            <IconWrapper>
              <img src={MissionIcon1} alt='iconMission' />
            </IconWrapper>
            <CardTitle> {t('missionBlock.cardTitle3')}</CardTitle>
            <CardText>{t('missionBlock.cardText3')}</CardText>
          </Card>

          <Card className={`fourth ${startAnimation ? 'animate' : ''}`}>
            <IconWrapper>
              <img src={MissionIcon4} alt='iconMission' />
            </IconWrapper>
            <CardTitle> {t('missionBlock.cardTitle4')}</CardTitle>
            <CardText>{t('missionBlock.cardText4')}</CardText>
          </Card>

          <Card className={`fifth ${startAnimation ? 'animate' : ''}`}>
            <IconWrapper>
              <img src={MissionIcon3} alt='iconMission' />
            </IconWrapper>
            <CardTitle> {t('missionBlock.cardTitle5')}</CardTitle>
            <CardText>{t('missionBlock.cardText5')}</CardText>
          </Card>
        </CardsGrid>
      </ContainerMission>
    </Container>
  );
};

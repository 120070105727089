import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './screens/app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { ModalProvider } from 'context/ModalContext';

// Импорт вашего провайдера

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.REACT_APP_MODE === 'dev') {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex';
  document.head.appendChild(meta);
}

root.render(
  <BrowserRouter>
    <React.StrictMode>
      {/* Вставляем провайдер, оборачивающий App */}
      <ModalProvider>
        <App />
      </ModalProvider>
    </React.StrictMode>
  </BrowserRouter>,
);

reportWebVitals();

import styled from 'styled-components';

export const CatalogBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 1120px) {
    padding: 50px 0px;
    gap: 30px;
  }
`;

export const Header = styled.p`
  color: #292d32;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const CatalogProjectsBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 1120px) {
    gap: 16px;
  }
`;

export const CardsStack = styled.div`
  position: relative;
  width: 35%;
  @media (max-width: 1120px) {
    width: 45%;
  }
  @media (max-width: 670px) {
    width: 100%;
    position: relative;
  }
`;

interface ProjectCardProps {
  isHovered: boolean;
  expandedHeight: number;
  normalHeight: number;
  topOffset: number;
  theme: string;
}

interface ProjectProps {
  theme: string;
}

export const ProjectCard = styled.div<ProjectCardProps>`
  position: absolute;
  top: ${({ topOffset }) => topOffset}px;
  left: 0;
  width: 100%;
  border: 1px solid #fff;
  box-shadow: 0px -3px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
  height: ${({ isHovered, expandedHeight, normalHeight }) =>
    isHovered ? expandedHeight : normalHeight}px;
  z-index: ${({ isHovered }) => (isHovered ? 5 : 1)};
  transition:
    transform 0.3s ease,
    height 0.3s ease,
    top 0.3s ease,
    background 0.3s ease;

  @media (max-width: 1120px) {
    padding: 10px;
  }

  @media (max-width: 670px) {
    position: static;
  }

  ${({ theme }) => {
    switch (theme) {
      case 'green':
        return `
          background: #B4DE00;
          color: #292D32;
          border: 1px solid #B4DE00;
        `;
      case 'white':
        return `
          background: #FFFFFF;
          color: #292D32;
          border: 1px solid #5784F7;

        `;
      default: // Black
        return `
          background: #1B1B1B;
          color: #FFF
          border: 1px solid #FFF;


        `;
    }
  }}

  &:hover {
    transform: translateX(0);
  }
`;

export const ProjectCardHeader = styled.div<ProjectProps>`
  display: flex;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;

  @media (max-width: 1120px) {
    gap: 8px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    white-space: nowrap; /* Предотвращаем перенос текста */

    @media (max-width: 1120px) {
      font-size: 14px;
    }
    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
          color: #184000;
        `;
        case 'white':
          return `
          color: #5784F7;
        `;
        default: // Black
          return `
          color: #FFF;
        `;
      }
    }}
  }
  p {
    text-align: right;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px; /* 115.385% */
    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
          color: #184000;
        `;
        case 'white':
          return `
          color: #5784F7;
        `;
        default: // Black
          return `
          color: #FFF;
        `;
      }
    }}
  }
`;

export const ProjectCardContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  @media (max-width: 1120px) {
    gap: 8px;
    margin-top: 8px;
  }
`;

export const ProjectCardContent = styled.div<ProjectProps>`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    width: 50%;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 2px;
    @media (max-width: 1120px) {
      padding: 6px 10px;
    }
    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
background: rgba(0, 0, 0, 0.05);
        `;
        case 'white':
          return `
background: rgba(240, 243, 255, 0.50);
        `;
        default: // Black
          return `
background: rgba(240, 243, 255, 0.05);
        `;
      }
    }}
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 150%; /* 18px */
      ${({ theme }) => {
        switch (theme) {
          case 'green':
            return `
color: #184000;
        `;
          case 'white':
            return `
color: #686F84;
        `;
          default: // Black
            return `
  color: #808185;
        `;
        }
      }}
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 15px; /* 93.75% */
      @media (max-width: 1120px) {
        font-size: 14px;
      }
      ${({ theme }) => {
        switch (theme) {
          case 'green':
            return `
color: #184000;
        `;
          case 'white':
            return `
color: #5784F7;
        `;
          default: // Black
            return `
  color: #FFF;
        `;
        }
      }}
    }
  }
`;

export const ButtonBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const CardFull = styled.div<ProjectProps>`
  display: flex;
  flex-direction: column;
  padding: 36px;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 12px;
  border: none;
  position: relative;
  height: 520px; /* Фиксированная высота */
  @media (max-width: 670px) {
    display: none;
  }

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  ${({ theme }) => {
    switch (theme) {
      case 'green':
        return `
  background: linear-gradient(100deg, #B4DE00 50%, #BBE11A 50%);
        `;
      case 'white':
        return `
  background: linear-gradient(100deg, #FFFFFF 50%, #F5F5F5 50%);
        `;
      default: // Black
        return `
  background: linear-gradient(100deg, #1b1b1b 50%, #222222 50%);

        `;
    }
  }}
`;

export const HeaderCardFull = styled.div<ProjectProps>`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    font-weight: 600;

    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
color: #184000;
        `;
        case 'white':
          return `
color: #292D32;
        `;
        default: // Black
          return `
  color: #FFF;

        `;
      }
    }}
  }
  p {
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap; /* Предотвращаем перенос текста */

    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
color: #184000;
        `;
        case 'white':
          return `
color: #292D32;
        `;
        default: // Black
          return `
color: #808185;

        `;
      }
    }}
  }
`;

export const CardFullIconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CardFullContentBoxList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const CardFullContentBox = styled.div<ProjectProps>`
  display: flex;
  width: 100%;
  gap: 24px;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    p {
      font-size: 20px;
      font-weight: 600;
      ${({ theme }) => {
        switch (theme) {
          case 'green':
            return `
color: #184000;
        `;
          case 'white':
            return `
color: #292D32;
        `;
          default: // Black
            return `
  color: #FFF;

        `;
        }
      }}
    }
    span {
      font-size: 16px;
      font-weight: 400;
      ${({ theme }) => {
        switch (theme) {
          case 'green':
            return `
color: #184000;
        `;
          case 'white':
            return `
  color: #808185;
        `;
          default: // Black
            return `
  color: #808185;

        `;
        }
      }}
    }
  }
`;

export const FooterCardFull = styled.div<ProjectProps>`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  justify-content: space-between;
  h4 {
    font-size: 16px;
    font-weight: 400;
    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
color: #184000;
        `;
        case 'white':
          return `
  color: #808185;
        `;
        default: // Black
          return `
  color: #808185;

        `;
      }
    }}
  }
  p {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    ${({ theme }) => {
      switch (theme) {
        case 'green':
          return `
color: #184000;
        `;
        case 'white':
          return `
color: #292D32;
        `;
        default: // Black
          return `
  color: #FFF;

        `;
      }
    }}
  }
`;

import styled from 'styled-components';
import { ReactComponent as ToggleBusinessIcon } from 'assets/img/ToggleBusiness.svg';
import { ReactComponent as ToggleInvestorIcon } from 'assets/img/ToggleInvestor.svg';
import { ReactComponent as ToggleBrokerIcon } from 'assets/img/ToggleBroker.svg';

export interface TabProps {
  active?: boolean;
}

export interface StepItemProps {
  active?: boolean;
}

// Типы шагов
export interface Step {
  title: string;
  video: string;
  description?: string;
}

// Типы для всех вкладок
export interface TabsData {
  Бизнес: { steps: Step[] };
  Инвестор: { steps: Step[] };
  'Брокеры и фонды': { steps: Step[] };
}

export type TabKey = keyof TabsData; // "Бизнес" | "Инвестор" | "Брокеры и фонды"

export const ContainerBox = styled.div`
  display: flex;
  padding: 150px 0px 150px 0px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 1120px) {
    padding: 50px 0px 50px 0px;
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 1120px) {
    gap: 16px;
  }
`;

export const LeftSideTitle = styled.h2`
  color: #292d32;
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 20px;
  @media (max-width: 1120px) {
    padding-left: 0px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1120px) {
    gap: 16px;
  }
`;

export const Tab = styled.div<TabProps>`
  display: flex;
  padding: 0px 12px 12px 12px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: color 0.3s;
  border-bottom: ${(props) => (props.active ? '2px solid #377DFF' : '2px solid #F0F3FF')};
  color: ${(props) => (props.active ? '#377DFF' : '#292D32')};
  font-size: 18px;

  @media (max-width: 1120px) {
    font-size: 16px;
    padding: 0px 8px 8px 8px;
  }

  &:hover {
    color: #377dff;
    border-bottom: 2px solid #377dff;
  }
  &:hover path {
    stroke: #377dff;
  }
`;

export const StepsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 1120px) {
    margin-top: 14px;
    gap: 8px;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepHeader = styled.div<StepItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? '600' : '600')};
  color: ${(props) => (props.active ? '#5784F7' : '#83878C')};
  font-size: 20px;

  @media (max-width: 1120px) {
    font-size: 16px;
  }

  /* Вертикальная черта слева */
  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background: ${(props) => (props.active ? '#377DFF' : '#F0F3FF')};
  }
`;

export const StepArrow = styled.div<StepItemProps>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-right: 2px solid ${(props) => (props.active ? '#377DFF' : '#999')};
    border-bottom: 2px solid ${(props) => (props.active ? '#377DFF' : '#999')};
    transform: translate(-50%, -50%) rotate(${(props) => (props.active ? '-135deg' : '45deg')});
    transition:
      transform 0.3s,
      border-color 0.3s;
  }
`;

export const StepDescriptionText = styled.div`
  margin-left: 12px;
  color: #666;
  font-size: 14px;
  margin-top: 8px;
`;

export const BottomButtons = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 12px;
  @media (max-width: 1120px) {
    margin-top: 16px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
`;

export const IconBase = (Component: React.ComponentType<{ className?: string }>) => styled(
  Component,
)<{
  active?: boolean;
}>`
  width: 20px;
  height: 20px;
  cursor: pointer;

  path {
    stroke: ${(props) => (props.active ? '#377DFF' : '#292D32')};
    transition: stroke 0.3s ease;
  }

  &:hover path {
    stroke: #377dff;
  }
`;

export const ToggleBusiness = IconBase(ToggleBusinessIcon);
export const ToggleInvestor = IconBase(ToggleInvestorIcon);
export const ToggleBroker = IconBase(ToggleBrokerIcon);

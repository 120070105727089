import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface InputProps {
  $hasError?: boolean;
}

const Inputt = styled.input<InputProps>`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #f8f9fd;
  color: #292d32;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  background: #f8f9fd;
  &:focus {
    outline: none;
    border-color: #5784f7; /* Замените на нужный цвет, если требуется */
  }
  ${({ $hasError: hasError }) =>
    hasError
      ? css`
          border: 1px solid ${colors.WARNING};
        `
      : ''}

  &.white {
    background: #181b3e;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const TitleInput = styled.p`
  color: #292d32;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
  &.white {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export { InputBox, Inputt, ErrorMessage, TitleInput };

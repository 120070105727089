import styled from 'styled-components';
import colors from 'global_styling/colors';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 150px;
  gap: 120px;
  flex-wrap: wrap;
  text-align: center;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px;
    justify-content: center;
    margin-bottom: 50px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .light {
    max-width: 190px;
  }
`;
const Number = styled.p`
  color: #5784f7;
  font-size: 32px;
  font-weight: 700;
  width: 200px; /* Фиксированная ширина для предотвращения дерганий */
  text-align: center; /* Центрируем число */
  white-space: nowrap; /* Предотвращаем перенос текста */
`;

const Title = styled.p`
  color: #808185;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export { Box, Card, Number, Title };

import styled from 'styled-components';

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  ul {
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }
`;

export const FAQTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 50px;
  text-align: center;
  color: #292d32;
  font-weight: 700;
`;

export const FAQItem = styled.div`
  border-top: 1px solid #f0f3ff;
  padding: 30px 0;
`;

export const QuestionRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
`;

export const Icon = styled.div`
  border-radius: 4px;
  background: #f0f3ff;
  padding: 12px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const Question = styled.div`
  color: #474747;
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
`;

export const StyledArrow = styled.div<{ isOpen: boolean }>`
  width: 24px;
  height: 25px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
  svg {
    display: block;
  }
`;

export const Answer = styled.div<{ isOpen: boolean }>`
  color: #474747;
  font-size: 16px;
  margin-top: 4px;
  margin-left: 64px;
  font-weight: 400;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  ul {
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }
`;

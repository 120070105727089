import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'index.style';
import { Box, Card, Number, Title } from './NumberSection.style';
import CountUp from 'react-countup';

const PROJECT_NUMBER = 431024614;
const CLIENTS_NUMBER = 141;
const TEAM_SIZE_NUMBER = 21163413;
const YEARS_MARKET_NUMBER = 450;

const START_NUMBER = 0;
const SECTION_START_ID = '#number-section';

// Параметры, используемые в CountUp и IntersectionObserver
const DEFAULT_START_COUNT = 0;
const DEFAULT_DURATION_COUNT = 6;
const DEFAULT_DELAY_COUNT = 0;
const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD_COUNT = 0.9;

const options = {
  rootMargin: DEFAULT_ROOT_MARGIN,
  threshold: DEFAULT_THRESHOLD_COUNT,
};

export const LightNumberSection: React.FC = () => {
  const [counterOn, setCounterOn] = useState(false);

  // Подключение i18n
  const { t } = useTranslation();
  // Забираем объект с переводами (returnObjects: true — чтобы i18next вернул вложенную структуру)
  const texts = t('lightNumberSection', { returnObjects: true }) as {
    projectLight: string;
    clientsLight: string;
    teamSize: string;
    yearsMarket: string;
    plus: string;
    tenge: string;
  };

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCounterOn(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const target = document.querySelector(SECTION_START_ID);

    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  return (
    <Container>
      <Box id='number-section'>
        {/* 1. Карточка: "Инвестировано" */}
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? PROJECT_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={texts.tenge} // Берем из переводов
              redraw={false}
            />
          </Number>
          <Title className='light'>{texts.projectLight}</Title>
        </Card>

        {/* 2. Карточка: "Проектов" */}
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? CLIENTS_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={texts.plus} // Берем из переводов
              redraw={false}
            />
          </Number>
          <Title className='light'>{texts.clientsLight}</Title>
        </Card>

        {/* 3. Карточка: "Выплачено дивидендов" */}
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? TEAM_SIZE_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={texts.tenge}
              redraw={false}
            />
          </Number>
          <Title className='light'>{texts.teamSize}</Title>
        </Card>

        {/* 4. Карточка: "Инвесторов" */}
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? YEARS_MARKET_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={texts.plus}
              redraw={false}
            />
          </Number>
          <Title className='light'>{texts.yearsMarket}</Title>
        </Card>
      </Box>
    </Container>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'index.style';
import {
  Card,
  CardGrid,
  MainAdvantagesBox,
  Tab,
  Tabs,
  Title,
  ToggleBusiness,
  ToggleInvestor,
} from './MainAdvantages.style';

import IconLaptopCloud from 'assets/img/IconLaptopCloud.svg';
import IconLegalDocument from 'assets/img/IconLegalDocument.svg';
import IconQuran from 'assets/img/IconQuran.svg';
import IconAnalytics from 'assets/img/IconAnalytics.svg';
import IconPayments from 'assets/img/IconPayments.svg';
import IconSpeed from 'assets/img/IconSpeed.svg';
import IconEnergy from 'assets/img/IconEnergy.svg';
import IconApproval from 'assets/img/IconApproval.svg';
import IconInternet from 'assets/img/IconInternet.svg';

// Пример соответствия иконок "title" → icon
// (Если хочется связать иконки с текстом - нужно как-то определить,
//  какой ключ "title" соответствует какой иконке.)
const iconMap: Record<string, string> = {
  'Скорость сделок': IconSpeed,
  'Мәмілелердің жылдамдығы': IconSpeed,
  'Transaction Speed': IconSpeed,

  Гибкость: IconEnergy,
  Икемділік: IconEnergy,
  Flexibility: IconEnergy,

  'Электронные документы': IconLaptopCloud,
  'Electronic Documents': IconLaptopCloud,
  'Электронды құжаттар': IconLaptopCloud,

  'Полностью онлайн': IconInternet,
  'Completely Online': IconInternet,
  'Толығымен онлайн': IconInternet,

  'Юридическая значимость': IconLegalDocument,
  'Legal Validity': IconLegalDocument,
  'Заңды маңыздылығы': IconLegalDocument,

  'Исламские инструменты': IconQuran,
  'Islamic Tools': IconQuran,
  'Исламдық құралдар': IconQuran,

  'Контроль над платежами': IconPayments,
  'Payment Control': IconPayments,
  'Төлемдерді бақылау': IconPayments,

  'Максимум анализа': IconAnalytics,
  'Maximum Analysis': IconAnalytics,
  'Максималды талдау': IconAnalytics,

  // ... при необходимости дополняйте соответствие
};

export const MainAdvantages: React.FC = () => {
  const { t } = useTranslation();

  // Достаем объект перевода целиком
  // { returnObjects: true } позволяет получить иерархический объект
  const mainAdv = t('mainAdvantages', { returnObjects: true }) as {
    title: string;
    tabs: { business: string; investor: string };
    business: { title: string; description: string }[];
    investor: { title: string; description: string }[];
  };

  const [activeTab, setActiveTab] = useState<'business' | 'investor'>('business');
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  // Определяем текущий список
  const currentList = activeTab === 'business' ? mainAdv.business : mainAdv.investor;

  return (
    <Container>
      <MainAdvantagesBox>
        {/* Заголовок, например: "Основные преимущества" */}
        <Title>{mainAdv.title}</Title>

        {/* ТабЫ */}
        <Tabs>
          <Tab active={activeTab === 'business'} onClick={() => setActiveTab('business')}>
            <ToggleBusiness active={activeTab === 'business'} />
            {mainAdv.tabs.business}
          </Tab>
          <Tab active={activeTab === 'investor'} onClick={() => setActiveTab('investor')}>
            <ToggleInvestor active={activeTab === 'investor'} />
            {mainAdv.tabs.investor}
          </Tab>
        </Tabs>

        <CardGrid>
          {currentList.map((item, index) => {
            // Найдем нужную иконку по заголовку (при желании)
            const iconSrc = iconMap[item.title] || IconApproval;

            return (
              <Card
                key={index}
                hovered={hoveredCard === index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}>
                {hoveredCard !== index && (
                  <img src={iconSrc} alt={item.title} className='card-icon' />
                )}
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </Card>
            );
          })}
        </CardGrid>
      </MainAdvantagesBox>
    </Container>
  );
};

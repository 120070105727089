import { MainBanner } from 'components/MainBanner';
import React from 'react';
import InvestorBanner from 'assets/img/InvestorBanner.png';
import Investor1 from 'assets/img/Investor1.webp';
import Investor2 from 'assets/img/Investor2.webp';
import Investor3 from 'assets/img/Investor3.webp';
import Investor4 from 'assets/img/Investor4.webp';
import Investor5 from 'assets/img/Investor5.webp';
import Investor6 from 'assets/img/Investor6.webp';
import { MainFeatures } from 'components/MainFeatures';
import { SuccessStories } from 'components/SuccessStories';
import { Subtitle } from 'components/SuccessStories/SuccessStories.style';
import HistoryFace1 from 'assets/img/FaceHistory1.webp';
import HistoryFace2 from 'assets/img/FaceHistory2.webp';
import HistoryFace3 from 'assets/img/FaceHistory3.webp';
import HistoryFace4 from 'assets/img/FaceHistory4.webp';
import HistoryFace5 from 'assets/img/FaceHistory5.webp';
import HistoryFace6 from 'assets/img/FaceHistory6.webp';
import { CreepingLine } from 'components/CreepingLine';
import { default as LineIcon1 } from 'assets/img/LineIcon1.svg';

import { default as LineIcon4 } from 'assets/img/LineIcon4.svg';
import { default as LineIcon5 } from 'assets/img/LineIcon5.svg';
import { default as LineIcon7 } from 'assets/img/LineIcon7.svg';
import { default as LineIcon8 } from 'assets/img/LineIcon8.svg';
import { default as LineIcon9 } from 'assets/img/LineIcon9.svg';
import { PresentProjects } from 'components/PresentProjects';
import { FinancingInstruments } from 'components/FinancingInstruments';
import { FAQBlock } from 'components/FAQBlock';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { useTranslation } from 'react-i18next';

const InvestorScreen = () => {
  const { t } = useTranslation();

  const cardsData = [
    {
      title: t('cardsDataInvestor.title1'),
      description: t('cardsDataInvestor.description1'),
      image: Investor1,
    },
    {
      title: t('cardsDataInvestor.title2'),
      description: t('cardsDataInvestor.description2'),
      image: Investor3,
    },
    {
      title: t('cardsDataInvestor.title3'),
      description: t('cardsDataInvestor.description3'),
      image: Investor6,
    },
    {
      title: t('cardsDataInvestor.title4'),
      description: t('cardsDataInvestor.description4'),
      image: Investor5,
    },
    {
      title: t('cardsDataInvestor.title5'),
      description: t('cardsDataInvestor.description5'),
      image: Investor2,
    },
    {
      title: t('cardsDataInvestor.title6'),
      description: t('cardsDataInvestor.description6'),
      image: Investor4,
    },
  ];

  const items = [
    { text: t('itemsInvestor.text1'), icon: LineIcon1 },
    { text: t('itemsInvestor.text2'), icon: LineIcon5 },
    { text: t('itemsInvestor.text3'), icon: LineIcon1 },
    { text: t('itemsInvestor.text4'), icon: LineIcon9 },
    {
      text: t('itemsInvestor.text5'),
      icon: LineIcon5,
    },
    { text: t('itemsInvestor.text6'), icon: LineIcon4 },
    { text: t('itemsInvestor.text7'), icon: LineIcon7 },
    { text: t('itemsInvestor.text8'), icon: LineIcon1 },
    { text: t('itemsInvestor.text9'), icon: LineIcon8 },
    { text: t('itemsInvestor.text2'), icon: LineIcon5 },
    { text: t('itemsInvestor.text3'), icon: LineIcon1 },
    { text: t('itemsInvestor.text4'), icon: LineIcon9 },
    {
      text: t('itemsInvestor.text5'),
      icon: LineIcon5,
    },
    { text: t('itemsInvestor.text6'), icon: LineIcon4 },
  ];

  const cardsDataInstrument = [
    {
      title: t('cardsDataInstrumentInvestor.title1'),
      description: t('cardsDataInstrumentInvestor.description1'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title2'),
      description: t('cardsDataInstrumentInvestor.description2'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title3'),
      description: t('cardsDataInstrumentInvestor.description3'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title4'),
      description: t('cardsDataInstrumentInvestor.description4'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title5'),
      description: t('cardsDataInstrumentInvestor.description5'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title6'),
      description: t('cardsDataInstrumentInvestor.description6'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title7'),
      description: t('cardsDataInstrumentInvestor.description7'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title8'),
      description: t('cardsDataInstrumentInvestor.description8'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title9'),
      description: t('cardsDataInstrumentInvestor.description9'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title10'),
      description: t('cardsDataInstrumentInvestor.description10'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title11'),
      description: t('cardsDataInstrumentInvestor.description11'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title12'),
      description: t('cardsDataInstrumentInvestor.description12'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title13'),
      description: t('cardsDataInstrumentInvestor.description13'),
    },
    {
      title: t('cardsDataInstrumentInvestor.title14'),
      description: t('cardsDataInstrumentInvestor.description14'),
    },
  ];

  const cards = [
    {
      title: t('cardsDataInvestor1.title1'),
      description: t('cardsDataInvestor1.description1'),
      example: t('cardsDataInvestor1.example1'),
    },
    {
      title: t('cardsDataInvestor1.title2'),
      description: t('cardsDataInvestor1.description2'),
      example: t('cardsDataInvestor1.example2'),
    },
    {
      title: t('cardsDataInvestor1.title3'),
      description: t('cardsDataInvestor1.description3'),
      example: t('cardsDataInvestor1.example3'),
    },
    {
      title: t('cardsDataInvestor1.title4'),
      description: t('cardsDataInvestor1.description4'),
      example: t('cardsDataInvestor1.example4'),
      isDown: true, // Указываем, что это карточка нижнего ряда
    },
  ];

  const faqData = [
    {
      question: t('faqDataInvestors.question1'),
      answer: (
        <>
          {t('faqDataInvestors.answer1')}
          <ul>
            <li>
              <strong>{t('faqDataInvestors.answer1.2')}</strong>
              {t('faqDataInvestors.answer1.3')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer1.4')}</strong>
              {t('faqDataInvestors.answer1.5')}
            </li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question2'),
      answer: (
        <>
          {t('faqDataInvestors.answer2')}
          <ol>
            <li>
              <strong>{t('faqDataInvestors.answer2.2')}</strong>
              {t('faqDataInvestors.answer2.3')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer2.4')}</strong>
              {t('faqDataInvestors.answer2.5')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer2.6')}</strong>
              {t('faqDataInvestors.answer2.7')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer2.8')}</strong>
              {t('faqDataInvestors.answer2.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question3'),
      answer: (
        <>
          <strong>{t('faqDataInvestors.answer3')}</strong>
          {t('faqDataInvestors.answer3.1')}
          <ul>
            <li>{t('faqDataInvestors.answer3.2')}</li>
            <li>{t('faqDataInvestors.answer3.3')}</li>
          </ul>
          <strong>{t('faqDataInvestors.answer3.4')}</strong>
          {t('faqDataInvestors.answer3.4.1')}
          <ul>
            <li>{t('faqDataInvestors.answer3.5')}</li>
            <li>{t('faqDataInvestors.answer3.6')}</li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question4'),
      answer: (
        <>
          {t('faqDataInvestors.answer4')}
          <ol>
            <li>
              <strong>{t('faqDataInvestors.answer4.2')}</strong>
              {t('faqDataInvestors.answer4.3')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer4.4')}</strong>
              {t('faqDataInvestors.answer4.5')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer4.6')}</strong>
              {t('faqDataInvestors.answer4.7')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer4.8')}</strong>
              {t('faqDataInvestors.answer4.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question5'),
      answer: (
        <>
          {t('faqDataInvestors.answer5')}
          <ol>
            <br />
            <li>
              <strong>{t('faqDataInvestors.answer5.1')}</strong>
              {t('faqDataInvestors.answer5.2')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer5.3')}</strong>
              {t('faqDataInvestors.answer5.4')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer5.5')}</strong>
              {t('faqDataInvestors.answer5.6')}
            </li>
          </ol>
          <br />
          {t('faqDataInvestors.answer5.7')}
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question6'),
      answer: (
        <>
          <p>{t('faqDataInvestors.answer6.1')}</p>
          <br />
          <p>{t('faqDataInvestors.answer6.2')}</p>
          <br />
          <p>{t('faqDataInvestors.answer6.3')}</p>
        </>
      ),
    },
    {
      question: t('faqDataInvestors.question7'),
      answer: (
        <>
          {t('faqDataInvestors.answer7')}
          <ol>
            <br />
            <li>
              <strong>{t('faqDataInvestors.answer7.2')}</strong>
              <br />
              {t('faqDataInvestors.answer7.3')}
            </li>
            <li>
              <strong>{t('faqDataInvestors.answer7.4')}</strong>
              <br />
              {t('faqDataInvestors.answer7.5')}
            </li>
          </ol>
          <br />
          {t('faqDataInvestors.answer7.6')}
        </>
      ),
    },
  ];

  const storiesData = [
    {
      avatars: [HistoryFace1],
      name: t('storiesData.story1.name'),
      role: t('storiesData.story1.role'),
      title: t('storiesData.story1.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story1.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story1.details.purpose'),
        amount: t('storiesData.story1.details.amount'),
        term: t('storiesData.story1.details.term'),
        form: t('storiesData.story1.details.form'),
        profitLabel: t('storiesData.story1.details.profitLabel'),
        profitValue: t('storiesData.story1.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace2],
      name: t('storiesData.story2.name'),
      role: t('storiesData.story2.role'),
      title: t('storiesData.story2.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story2.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story2.details.purpose'),
        amount: t('storiesData.story2.details.amount'),
        term: t('storiesData.story2.details.term'),
        form: t('storiesData.story2.details.form'),
        profitLabel: t('storiesData.story2.details.profitLabel'),
        profitValue: t('storiesData.story2.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace3],
      name: t('storiesData.story3.name'),
      role: t('storiesData.story3.role'),
      title: t('storiesData.story3.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story3.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story3.details.purpose'),
        amount: t('storiesData.story3.details.amount'),
        term: t('storiesData.story3.details.term'),
        form: t('storiesData.story3.details.form'),
        profitLabel: t('storiesData.story3.details.profitLabel'),
        profitValue: t('storiesData.story3.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace4],
      name: t('storiesData.story4.name'),
      role: t('storiesData.story4.role'),
      title: t('storiesData.story4.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story4.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story4.details.purpose'),
        amount: t('storiesData.story4.details.amount'),
        term: t('storiesData.story4.details.term'),
        form: t('storiesData.story4.details.form'),
        profitLabel: t('storiesData.story4.details.profitLabel'),
        profitValue: t('storiesData.story4.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace5],
      name: t('storiesData.story5.name'),
      role: t('storiesData.story5.role'),
      title: t('storiesData.story5.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story5.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story5.details.purpose'),
        amount: t('storiesData.story5.details.amount'),
        term: t('storiesData.story5.details.term'),
        form: t('storiesData.story5.details.form'),
        profitLabel: t('storiesData.story5.details.profitLabel'),
        profitValue: t('storiesData.story5.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace6],
      name: t('storiesData.story6.name'),
      role: t('storiesData.story6.role'),
      title: t('storiesData.story6.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story6.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story6.details.purpose'),
        amount: t('storiesData.story6.details.amount'),
        term: t('storiesData.story6.details.term'),
        form: t('storiesData.story6.details.form'),
        profitLabel: t('storiesData.story6.details.profitLabel'),
        profitValue: t('storiesData.story6.details.profitValue'),
      },
    },
  ];

  return (
    <>
      <MainBanner
        tagText={t('InvestorScreen.tagText')}
        headerLine1={t('InvestorScreen.tagText')}
        headerHighlight1=''
        headerLine2=''
        headerHighlight2=''
        firstButtonText={t('InvestorScreen.tagText')}
        secondButtonText={t('BrokerScreen.secondButtonText')}
        imageSrc={InvestorBanner}
        description={t('InvestorScreen.tagText')}
      />
      <div id='main-features-investor'>
        <MainFeatures
          cards={cardsData}
          defaultImage='https://via.placeholder.com/800x400?text=Default+Image'
        />
      </div>
      <div id='success-stories-investor'>
        <SuccessStories stories={storiesData} sectionTitle={t('storiesData.header')} />
      </div>
      <div id='creeping-line-investor'>
        <CreepingLine items={items} title={t('itemsInvestor.title')} />
      </div>
      <div id='present-project-investor'>
        <PresentProjects title={t('cardsDataInvestor1.title')} cards={cards} />
      </div>
      <div id='financing-instrument-investor'>
        <FinancingInstruments
          title={t('cardsDataInstrumentInvestor.title')}
          cardsData={cardsDataInstrument}
          offsetRadius={2}
        />
      </div>
      <FAQBlock faqData={faqData} title={t('faqDataInvestors.title')} />;
      <FeedbackBlock />
    </>
  );
};

export default InvestorScreen;

import styled from 'styled-components';

export const BoxTariff = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px 150px 0px;
  @media (max-width: 1120px) {
    padding: 50px 0px 50px 0px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: #292d32;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 50px;
  @media (max-width: 1120px) {
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const TariffsWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const TariffCard = styled.div<{ variant?: 'standard' | 'pro' }>`
  max-width: 50%;
  border-radius: 24px;
  overflow: hidden;
  background: ${({ variant }) => (variant === 'pro' ? '#5784F7' : '#FFF')};
  border: 1px solid #5784f7;

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const TariffHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TariffTag = styled.p<{ variant?: 'standard' | 'pro' }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  color: ${({ variant }) => (variant === 'pro' ? '#FFF' : '#5784F7')};
`;

export const TariffTitle = styled.p<{ variant?: 'standard' | 'pro' }>`
  font-size: 32px;
  font-weight: 700;
  color: ${({ variant }) => (variant === 'pro' ? '#FFF' : '#292D32')};
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const TariffSubtitle = styled.p<{ variant?: 'standard' | 'pro' }>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ variant }) => (variant === 'pro' ? '#FFF' : '#686F84')};
  @media (max-width: 1120px) {
    font-size: 12px;
  }
`;

export const TariffBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FeatureItem = styled.div<{
  active?: boolean;
  variant?: 'standard' | 'pro';
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  line-height: 1.5;

  color: ${({ active, variant }) => {
    // Если тариф "pro", текст будет белым
    if (variant === 'pro') return '#FFF';
    // Иначе: активный – синий, неактивный – красный
    return active ? '#5784F7' : '#F75757';
  }};

  background: ${({ active, variant }) => {
    // Если тариф "pro", всегда слегка белый фон
    if (variant === 'pro') {
      return 'rgba(255, 255, 255, 0.10)';
    }
    // Иначе (стандарт), подкрашиваем в зависимости от активности
    return active ? 'rgba(87, 132, 247, 0.10)' : 'rgba(247, 87, 87, 0.10)';
  }};

  border-radius: 12px;
  padding: 8px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const TariffFooter = styled.div<{ variant?: 'standard' | 'pro' }>`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 12px;
  }
`;

export const TariffBase = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: #f0f3ff;

  color: #5784f7;

  font-size: 14px;
  font-weight: 600;
`;

export const PackagesRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RadioOptionRow = styled.label<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: ${({ selected }) => {
    return selected ? '1px solid #FFF;' : '1px solid rgba(255, 255, 255, 0.10)';
  }};
`;

export const HiddenRadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

export const RadioIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const RadioLabel = styled.span`
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;

export const RadioPrice = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;

export const PackageNote = styled.div`
  margin-top: 12px;
  color: #fff;

  font-size: 12px;
  font-weight: 500;
`;

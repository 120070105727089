import { CatalogProjects } from 'components/CatalogProjects';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { MainAdvantages } from 'components/MainAdvantages';
import { MainBanner } from 'components/MainBanner';
import { LightNumberSection } from 'components/NumberSection';
import { MissionBlock } from 'components/MissionBlock';
import { VideoBlock } from 'components/VideoBlock';
import MainVideo from 'assets/img/MainVideo.mp4';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MainScreen = () => {
  const { t } = useTranslation();
  const catalogProjectsRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    // Прокрутка к "Проекты", если hash в URL
    if (location.hash === '#projects' && catalogProjectsRef.current) {
      catalogProjectsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <MainBanner
        tagText={t('mainScreen.tagText')}
        headerLine1={t('mainScreen.headerLine1')}
        headerHighlight1={t('mainScreen.headerHighlight1')}
        headerLine2={t('mainScreen.headerLine2')}
        headerHighlight2={t('mainScreen.headerHighlight2')}
        firstButtonText={t('mainScreen.firstButtonText')}
        secondButtonText={t('mainScreen.secondButtonText')}
        videoSrc={MainVideo}
      />
      <MainAdvantages />
      <div ref={catalogProjectsRef}>
        <CatalogProjects />
      </div>
      <VideoBlock />
      <LightNumberSection />
      <MissionBlock />
      <FeedbackBlock />
    </>
  );
};

export default MainScreen;

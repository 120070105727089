import styled, { keyframes } from 'styled-components';

// Анимация для движения влево
export const scrollLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
`;

// Анимация для движения вправо
export const scrollRight = keyframes`
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0%);
  }
`;

// Контейнер для строк
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  overflow: hidden;
  padding: 0px 0px 150px 0px;
  width: 100%;

  @media (max-width: 1120px) {
    padding: 0px 0px 50px 0px;
  }
`;

export const Title = styled.h2`
  color: #292d32;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

// Полоса с дублированным контентом
export const Line = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  white-space: nowrap;
  animation: ${({ direction }) => (direction === 'left' ? scrollLeft : scrollRight)} 30s linear
    infinite; /* Бесконечная анимация */
  @media (max-width: 1120px) {
    animation: ${({ direction }) => (direction === 'left' ? scrollLeft : scrollRight)} 20s linear
      infinite; /* Бесконечная анимация */
  }

  @media (max-width: 500px) {
    animation: ${({ direction }) => (direction === 'left' ? scrollLeft : scrollRight)} 10s linear
      infinite; /* Бесконечная анимация */
  }
`;

// Карточка
export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 50px;
  border: 2px solid #f8f9fd;
  background: #f8f9fd;
  backdrop-filter: blur(25px);
  padding: 12px;
  margin: 0 24px;
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  flex: 0 0 auto;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

// Иконка в карточке
export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

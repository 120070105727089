import styled from 'styled-components';
import Slider from 'react-slick';

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    opacity: 0.5;
    transition:
      transform 0.3s,
      opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-center {
    opacity: 1;
    z-index: 2;
  }

  .slick-prev,
  .slick-next {
    z-index: 3;
    color: #5784f7;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev {
    left: 0px; /* Расстояние от карточек */
  }

  .slick-next {
    right: 0px; /* Расстояние от карточек */
  }

  .slick-prev:before,
  .slick-next:before {
    color: #5784f7;
    font-size: 24px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 260px; /* Фиксированная высота */
  width: 100%;
  max-width: 350px;
  padding: 24px;
  gap: 12px;
  cursor: pointer;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  border: 1px solid #fff;

  @media (max-width: 1120px) {
    min-height: 200px; /* Фиксированная высота */
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    /* border: 1px solid #5784f7; */
    background: rgba(87, 132, 247, 0.05);
  }

  &.active {
    border-radius: 6px;
    border: 1px solid #5784f7;
    background: rgba(87, 132, 247, 0.05);
  }

  h3 {
    color: #292d32;
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 1120px) {
      font-size: 16px;
    }
  }

  p {
    color: #292d32;
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
    @media (max-width: 1120px) {
      font-size: 14px;
    }
  }
`;

export const MainFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 150px 0px;
  @media (max-width: 1120px) {
    padding: 50px 0px;
  }

  h2 {
    color: #292d32;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
    @media (max-width: 1120px) {
      font-size: 20px;
    }
  }
`;

export const ImageContainer = styled.div`
  max-width: 800px;
  margin-top: 40px;

  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

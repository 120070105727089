import {
  BoxContent,
  BoxMain,
  Paragraph,
  TextBox,
  Title,
} from 'components/UserAgreementBlock/UserAgreementBlock.style';
import Container from 'index.style';
import React from 'react';
import { useTranslation } from 'react-i18next';

// 1. Описываем структуру разделов (sections) и абзацев (paragraphs)
interface PublicOfferSection {
  title: string | null; // У вас бывают секции без title, значит можно указать title?: string
  paragraphs: string[];
}

interface PublicOfferData {
  title: string;
  sections: PublicOfferSection[];
}

// 2. Компонент
export const PublicAgreementBlock: React.FC = () => {
  const { t } = useTranslation();

  // 3. Получаем данные и приводим (as) к нашему интерфейсу
  //    Так TypeScript узнает, какие поля там есть (title, sections и т.д.)
  const rawData = t('publicOfferBlock', { returnObjects: true });
  const publicOffer = rawData as PublicOfferData;

  return (
    <Container>
      <BoxMain>
        <TextBox>
          <h3>{publicOffer.title}</h3>
        </TextBox>

        {publicOffer.sections.map((section: PublicOfferSection, i: number) => (
          <BoxContent key={i}>
            {/* Если title может быть null, проверяем */}
            {section.title && <Title>{section.title}</Title>}

            {/* Указываем типы параметров, чтобы не было ошибок "implicitly has any type" */}
            {section.paragraphs.map((paragraph: string, j: number) => (
              <Paragraph key={j}>{paragraph}</Paragraph>
            ))}
          </BoxContent>
        ))}
      </BoxMain>
    </Container>
  );
};

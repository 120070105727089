import {
  BoxContent,
  BoxMain,
  Paragraph,
  PrivacyPolicyLink,
  TextBox,
  Title,
} from 'components/UserAgreementBlock/UserAgreementBlock.style';
import Container from 'index.style';
import { useTranslation } from 'react-i18next';

export const RiskAgreementBlock = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <BoxMain>
          <TextBox>
            <h3>{t('cardsDataRiskAgreement.title1')}</h3>
          </TextBox>
          <BoxContent>
            <Title>
              {t('cardsDataRiskAgreement.description1')}
              <PrivacyPolicyLink href='https://lend.kz'>
                {t('cardsDataRiskAgreement.privacy')}
              </PrivacyPolicyLink>
            </Title>
            <Paragraph>
              {t('cardsDataRiskAgreement.text1')}
              <br />
              <br />
              {t('cardsDataRiskAgreement.text2')}
              <br />
              <br />
              {t('cardsDataRiskAgreement.text3')}
              <br />
              <br />
              {t('cardsDataRiskAgreement.text4')}
              <br />
              <br />
              {t('cardsDataRiskAgreement.text5')}
            </Paragraph>

            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title3')}</b>
              {t('cardsDataRiskAgreement.description3')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title4')}</b>
              {t('cardsDataRiskAgreement.description4')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title5')}</b>
              {t('cardsDataRiskAgreement.description5')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title6')}</b>
              {t('cardsDataRiskAgreement.description6')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title7')}</b>
              {t('cardsDataRiskAgreement.description7')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title8')}</b>
              {t('cardsDataRiskAgreement.description8')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title9')}</b>
              {t('cardsDataRiskAgreement.description9')}
            </Paragraph>
            <Paragraph>
              <b>{t('cardsDataRiskAgreement.title10')}</b>
              {t('cardsDataRiskAgreement.description10')}
            </Paragraph>
            <Paragraph>{t('cardsDataRiskAgreement.description11')}</Paragraph>
          </BoxContent>
        </BoxMain>
      </Container>
    </>
  );
};

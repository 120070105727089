import Container from 'index.style';
import React, { useState } from 'react';
import {
  ButtonBox,
  CardFull,
  CardFullContentBox,
  CardFullContentBoxList,
  CardFullIconBox,
  CardsStack,
  CatalogBox,
  CatalogProjectsBox,
  FooterCardFull,
  Header,
  HeaderCardFull,
  ProjectCard,
  ProjectCardContent,
  ProjectCardContentList,
  ProjectCardHeader,
} from './CatalogProjects.style';
import { Button } from 'components/common/Button';
import { default as ChipCardWhite } from 'assets/img/ChipCardWhite.svg';
import { default as WIFICardWhite } from 'assets/img/WIFICardWhite.svg';
import { useTranslation } from 'react-i18next'; // Подключение перевода
import { refURL } from 'URL';

type Project = {
  id: string;
  title: string;
  amount: string;
  interestRate: string;
  profit: string;
  EntryAmount: string;
  product: string;
  pledge: string;
  duration: string;
  rating: string;
  theme: string;
};

export const CatalogProjects: React.FC = () => {
  const { t } = useTranslation(); // Хук для перевода
  const [hoveredCard, setHoveredCard] = useState<string | null>('RL-1217');

  const projects: Project[] = [
    {
      id: 'RL-1217',
      title: t('catalog.projects.project1.title'),
      amount: '120 200 000 ₸',
      EntryAmount: '5 M',
      interestRate: '4.50%',
      profit: '21 600 000 ₸',
      product: t('catalog.projects.project1.product'),
      pledge: t('catalog.projects.project1.pledge'),
      duration: '4 мес',
      rating: 'А+',
      theme: 'black',
    },
    {
      id: 'RL-1218',
      title: t('catalog.projects.project2.title'),
      amount: '45 000 000 ₸',
      EntryAmount: '3 M',
      interestRate: '3.50%',
      profit: '14 175 000 ₸',
      product: t('catalog.projects.project2.product'),
      pledge: t('catalog.projects.project2.pledge'),
      duration: '9 мес',
      rating: 'А+',
      theme: 'green',
    },
    {
      id: 'RL-1219',
      title: t('catalog.projects.project3.title'),
      amount: '70 000 000 ₸',
      EntryAmount: '10 M',
      product: t('catalog.projects.project3.product'),
      interestRate: '5%',
      profit: '42 000 000 ₸',
      pledge: t('catalog.projects.project3.pledge'),
      duration: '12 мес',
      rating: 'B+',
      theme: 'black',
    },
    {
      id: 'RL-1220',
      title: t('catalog.projects.project4.title'),
      amount: '30 000 000 ₸',
      EntryAmount: '3 M',
      interestRate: '5.00%',
      profit: '13 500 000 ₸',
      product: t('catalog.projects.project4.product'),
      pledge: t('catalog.projects.project4.pledge'),
      duration: '9 мес',
      rating: 'B-',
      theme: 'green',
    },
    {
      id: 'RL-1221',
      title: t('catalog.projects.project5.title'),
      amount: '10 000 000 ₸',
      EntryAmount: '3 M',
      interestRate: '6.00%',
      profit: '10 800 000 ₸',
      product: t('catalog.projects.project5.product'),
      pledge: t('catalog.projects.project5.pledge'),
      duration: '18 мес',
      rating: 'C+',
      theme: 'white',
    },
    {
      id: 'RL-1222',
      title: t('catalog.projects.project6.title'),
      amount: '106 000 000 ₸',
      EntryAmount: '5 M',
      interestRate: '3%',
      profit: '38 160 000 ₸',
      product: t('catalog.projects.project6.product'),
      pledge: t('catalog.projects.project6.pledge'),
      duration: '12 мес',
      rating: 'C+',
      theme: 'black',
    },
    {
      id: 'RL-1223',
      title: t('catalog.projects.project7.title'),
      amount: '80 000 000 ₸',
      EntryAmount: '5 M',
      interestRate: '3.30%',
      profit: '31 680 000 ₸',
      product: t('catalog.projects.project7.product'),
      pledge: t('catalog.projects.project7.pledge'),
      duration: '12 мес',
      rating: 'B+',
      theme: 'white',
    },
    {
      id: 'RL-1224',
      title: t('catalog.projects.project8.title'),
      amount: '50 000 000 ₸',
      EntryAmount: '5 M',
      interestRate: '3.00%',
      profit: '18 000 000 ₸',
      product: t('catalog.projects.project8.product'),
      pledge: t('catalog.projects.project8.pledge'),
      duration: '12 мес',
      rating: 'B',
      theme: 'black',
    },
  ];

  const normalHeight = 50;
  const expandedHeight = 180;

  return (
    <Container>
      <CatalogBox>
        <Header>{t('catalog.header')}</Header>
        <CatalogProjectsBox>
          <>
            <CardsStack>
              {projects.map((project, index) => {
                let topOffset = index * normalHeight;

                if (hoveredCard) {
                  const hoveredIndex = projects.findIndex((p) => p.id === hoveredCard);
                  if (index > hoveredIndex) {
                    topOffset += expandedHeight - normalHeight;
                  }
                }

                return (
                  <ProjectCard
                    key={project.id}
                    isHovered={hoveredCard === project.id}
                    normalHeight={normalHeight}
                    expandedHeight={expandedHeight}
                    topOffset={topOffset}
                    theme={project.theme}
                    onMouseEnter={() => setHoveredCard(project.id)}
                    onClick={() => setHoveredCard(project.id)}>
                    <ProjectCardHeader theme={project.theme}>
                      <h3>{project.id}</h3>
                      <p>{project.title}</p>
                    </ProjectCardHeader>
                    {hoveredCard === project.id ? (
                      <ProjectCardContentList>
                        <ProjectCardContent theme={project.theme}>
                          <div>
                            <p>{t('catalog.labels.deal')}</p>
                            <span>{project.id}</span>
                          </div>
                          <div>
                            <p>{t('catalog.labels.loan')}</p>
                            <span>{project.amount}</span>
                          </div>
                        </ProjectCardContent>
                        <ProjectCardContent theme={project.theme}>
                          <div>
                            <p>{t('catalog.labels.monthRate')}</p>
                            <span>{project.interestRate}</span>
                          </div>
                          <div>
                            <p>{t('catalog.labels.profit')}</p>
                            <span>{project.profit}</span>
                          </div>
                        </ProjectCardContent>
                      </ProjectCardContentList>
                    ) : null}
                  </ProjectCard>
                );
              })}
            </CardsStack>
          </>
          <CardFull theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
            {hoveredCard ? (
              <>
                <HeaderCardFull
                  theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                  <h2>{projects.find((p) => p.id === hoveredCard)?.title}</h2>
                  <p>{projects.find((p) => p.id === hoveredCard)?.id}</p>
                </HeaderCardFull>
                <CardFullIconBox>
                  <img src={ChipCardWhite} alt='icon' />
                  <img src={WIFICardWhite} alt='icon' />
                </CardFullIconBox>
                <CardFullContentBoxList>
                  <CardFullContentBox
                    theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                    <div>
                      <p>{projects.find((p) => p.id === hoveredCard)?.amount}</p>
                      <span>{t('catalog.labels.totalAmount')}</span>
                    </div>
                    <div>
                      <p>{projects.find((p) => p.id === hoveredCard)?.profit}</p>
                      <span>{t('catalog.labels.investorProfit')}</span>
                    </div>
                  </CardFullContentBox>
                  <CardFullContentBox
                    theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                    <div>
                      <p>{projects.find((p) => p.id === hoveredCard)?.EntryAmount}</p>
                      <span>{t('catalog.labels.entryThreshold')}</span>
                    </div>
                    <div>
                      <p>{projects.find((p) => p.id === hoveredCard)?.interestRate}</p>
                      <span>{t('catalog.labels.monthRate')}</span>
                    </div>
                  </CardFullContentBox>
                  <CardFullContentBox
                    theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                    {' '}
                    <div>
                      {' '}
                      <p>{projects.find((p) => p.id === hoveredCard)?.product}</p>{' '}
                      <span>{t('catalog.labels.investmentForm')}</span>{' '}
                    </div>{' '}
                    <div>
                      {' '}
                      <p>{projects.find((p) => p.id === hoveredCard)?.pledge}</p>{' '}
                      <span>{t('catalog.labels.pledge')}</span>{' '}
                    </div>{' '}
                  </CardFullContentBox>{' '}
                  <CardFullContentBox
                    theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                    {' '}
                    <div>
                      {' '}
                      <p>{projects.find((p) => p.id === hoveredCard)?.duration}</p>{' '}
                      <span>{t('catalog.labels.duration')}</span>{' '}
                    </div>{' '}
                    <div>
                      {' '}
                      <p>{projects.find((p) => p.id === hoveredCard)?.rating}</p>{' '}
                      <span>{t('catalog.labels.rating')}</span>{' '}
                    </div>{' '}
                  </CardFullContentBox>{' '}
                </CardFullContentBoxList>{' '}
                <FooterCardFull
                  theme={projects.find((p) => p.id === hoveredCard)?.theme || 'black'}>
                  {' '}
                  <h4>John Doe * 06 / 25</h4> <p>Lend.kz</p>{' '}
                </FooterCardFull>{' '}
              </>
            ) : (
              <p>{t('catalog.hoverMessage')}</p>
            )}{' '}
          </CardFull>{' '}
        </CatalogProjectsBox>{' '}
        <ButtonBox>
          {' '}
            <a href={refURL} rel='noopener noreferrer'>
            {' '}
            <Button text={t('catalog.goToPlatform')} />{' '}
          </a>{' '}
        </ButtonBox>{' '}
      </CatalogBox>{' '}
    </Container>
  );
};

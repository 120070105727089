import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from 'index.style';
import IconStar from 'assets/img/IconStar.png';
import {
  Card,
  Header,
  ImageContainer,
  MainFeaturesWrapper,
  StyledSlider,
} from './MainFeatures.style';
import { useTranslation } from 'react-i18next';

interface Card {
  title: string;
  description: string;
  image: string;
}

interface MainFeaturesProps {
  cards: Card[];
  defaultImage: string;
}

export const MainFeatures: React.FC<MainFeaturesProps> = ({ cards, defaultImage }) => {
  const { t } = useTranslation();

  const [activeCard, setActiveCard] = useState<number>(0);

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    beforeChange: (_: number, next: number) => setActiveCard(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <MainFeaturesWrapper>
        <h2>{t('cardsDataBusiness.title')}</h2>
        <StyledSlider {...settings}>
          {cards.map((card, index) => (
            <Card key={index} className={activeCard === index ? 'active' : ''}>
              <Header>
                <img src={IconStar} alt='icon' />
                <h3>{card.title}</h3>
              </Header>
              <p>{card.description}</p>
            </Card>
          ))}
        </StyledSlider>
        <ImageContainer>
          <img
            src={activeCard !== null ? cards[activeCard].image : defaultImage}
            alt='Main feature'
          />
        </ImageContainer>
      </MainFeaturesWrapper>
    </Container>
  );
};

import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const Container = styled.div`
  padding: 0px 0px 150px 0px;
  @media (max-width: 1120px) {
    padding: 0px 0px 50px 0px;
  }
`;

export const Title = styled.h2`
  color: #292d32;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
`;

export const CarouselContainer = styled.div`
  width: 50%;
  height: 400px;
  margin: 0 auto;
  position: relative;
`;

export const CardContainer = styled(animated.div)`
  background: #f8f9fd;
  border-radius: 6px;
  padding: 24px;
  gap: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: 334px;
  width: 350px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
`;

export const CardTitle = styled.h3`
  color: #5784f7;
  font-size: 32px;
  font-weight: 700;
`;

export const CardDescription = styled.p`
  color: #808185;
  font-size: 16px;
  font-weight: 400;
`;

export const ArrowButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

export const ArrowButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: #5784f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;

  &:hover {
    background-color: #3b5dab;
    transform: scale(1.1);
  }
`;

export const LeftArrow = styled(ArrowButton)`
  svg {
    transform: rotate(180deg);
  }
`;

export const RightArrow = styled(ArrowButton)``;

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0px 0px 150px 0px;
  @media (max-width: 1120px) {
    padding: 0px 0px 50px 0px;
  }
`;

export const Title = styled.h1`
  color: #292d32;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: 1120px) {
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const Grid = styled.div`
  display: grid;
  /* Здесь фиксированно 2 столбца, чтобы было по 2 карточки в ряд */
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media (max-width: 1120px) {
    gap: 30px;
  }
  @media (max-width: 777px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  border-radius: 6px;
  background: #f8f9fd;
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  max-width: 540px;
  @media (max-width: 1120px) {
    padding: 16px;
  }
  @media (max-width: 777px) {
    max-width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AvatarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: -12px;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  @media (max-width: 1120px) {
    width: 60px;
    height: 60px;
  }
`;

export const PersonInfo = styled.div`
  text-align: center;
  margin-top: 12px;
`;

export const Name = styled.div`
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

export const Role = styled.div`
  color: #808185;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

export const CardTitle = styled.h2`
  color: #5784f7;
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const CardDescription = styled.div`
  color: #808185;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

export const Subtitle = styled.div`
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 50%;
`;

export const DetailBox = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const DetailBoxProfit = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DetailLabel = styled.span`
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

export const DetailValue = styled.span`
  color: #808185;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

export const Profit = styled.div`
  border-radius: 6px;
  border: 1px solid #5784f7;
  background: rgba(87, 132, 247, 0.03);
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  @media (max-width: 1120px) {
    font-size: 6px;
  }

  span {
    color: #292d32;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    gap: 4px;
    align-items: center;
    @media (max-width: 1120px) {
      font-size: 16px;
    }
  }
`;

export const ProfitValue = styled.p`
  margin-top: 4px;
  color: #5784f7;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

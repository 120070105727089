import React from 'react';

// Иконки
import { default as RedCross } from 'assets/img/RedCross.svg';
import { default as WhiteCheck } from 'assets/img/WhiteCheck.svg';
import { default as BlueCheck } from 'assets/img/BlueCheck.svg';
import { default as NotCheckButton } from 'assets/img/NotCheckButton.svg';
import { default as CheckButton } from 'assets/img/CheckButton.svg';

// Стили и компоненты
import Container from 'index.style';
import { Button } from 'components/common/Button';
import {
  BoxTariff,
  FeatureItem,
  HiddenRadioInput,
  Icon,
  PackageNote,
  PackagesRadioGroup,
  RadioIcon,
  RadioLabel,
  RadioOptionRow,
  RadioPrice,
  TariffBase,
  TariffBody,
  TariffCard,
  TariffFooter,
  TariffHeader,
  TariffSubtitle,
  TariffsWrapper,
  TariffTag,
  TariffTitle,
  Title,
} from './Tariff.style';

// i18n
import { useTranslation } from 'react-i18next';

export const Tariff: React.FC = () => {
  const { t } = useTranslation();

  // Состояние выбора пакета (радиокнопки)
  const [selectedPackage, setSelectedPackage] = React.useState<'compact' | 'medium' | 'premium'>(
    'medium',
  );

  return (
    <Container>
      <BoxTariff>
        <Title>{t('tariffs.title')}</Title>

        <TariffsWrapper>
          <TariffCard variant='standard'>
            <TariffTag>{t('tariffs.standard')}</TariffTag>
            <TariffHeader>
              <TariffTitle>{t('tariffs.standard')}</TariffTitle>

              <TariffSubtitle>{t('tariffs.features.basicDesc')}</TariffSubtitle>
            </TariffHeader>

            <TariffBody>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature1')}
              </FeatureItem>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature2')}
              </FeatureItem>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature3')}
              </FeatureItem>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature4')}
              </FeatureItem>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature5')}
              </FeatureItem>
              <FeatureItem active>
                <Icon src={BlueCheck} alt='check' />
                {t('tariffs.features.feature6')}
              </FeatureItem>

              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature7')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature8')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature9')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature10')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature11')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature12')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature13')}
              </FeatureItem>
              <FeatureItem>
                <Icon src={RedCross} alt='cross' />
                {t('tariffs.features.feature14')}
              </FeatureItem>
            </TariffBody>

            <TariffBase>{t('tariffs.currentPlan')}</TariffBase>
          </TariffCard>

          <TariffCard variant='pro'>
            <TariffTag variant='pro'>{t('tariffs.optimal')}</TariffTag>
            <TariffHeader>
              <TariffTitle variant='pro'>{t('tariffs.pro')}</TariffTitle>

              <TariffSubtitle variant='pro'>{t('tariffs.features.basicDesc')}</TariffSubtitle>
            </TariffHeader>

            <TariffBody>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature1')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature2')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature3')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature4')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature5')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature6')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature7')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature8')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature9')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature10')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature11')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature12')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature13')}
              </FeatureItem>
              <FeatureItem active variant='pro'>
                <Icon src={WhiteCheck} alt='check' />
                {t('tariffs.features.feature14')}
              </FeatureItem>
            </TariffBody>

            <TariffFooter variant='pro'>
              <PackagesRadioGroup>
                <RadioOptionRow selected={selectedPackage === 'compact'}>
                  <HiddenRadioInput
                    name='proPackage'
                    value='compact'
                    checked={selectedPackage === 'compact'}
                    onChange={() => setSelectedPackage('compact')}
                  />
                  <RadioIcon
                    src={selectedPackage === 'compact' ? CheckButton : NotCheckButton}
                    alt='radio'
                  />
                  <RadioLabel>{t('tariffs.radio.compact')}</RadioLabel>

                  <RadioPrice>{t('tariffs.prices.compact')}</RadioPrice>
                </RadioOptionRow>

                <RadioOptionRow selected={selectedPackage === 'medium'}>
                  <HiddenRadioInput
                    name='proPackage'
                    value='medium'
                    checked={selectedPackage === 'medium'}
                    onChange={() => setSelectedPackage('medium')}
                  />
                  <RadioIcon
                    src={selectedPackage === 'medium' ? CheckButton : NotCheckButton}
                    alt='radio'
                  />
                  <RadioLabel>{t('tariffs.radio.medium')}</RadioLabel>
                  <RadioPrice>{t('tariffs.prices.medium')}</RadioPrice>
                </RadioOptionRow>

                <RadioOptionRow selected={selectedPackage === 'premium'}>
                  <HiddenRadioInput
                    name='proPackage'
                    value='premium'
                    checked={selectedPackage === 'premium'}
                    onChange={() => setSelectedPackage('premium')}
                  />
                  <RadioIcon
                    src={selectedPackage === 'premium' ? CheckButton : NotCheckButton}
                    alt='radio'
                  />
                  <RadioLabel>{t('tariffs.radio.premium')}</RadioLabel>
                  <RadioPrice>{t('tariffs.prices.premium')}</RadioPrice>
                </RadioOptionRow>
              </PackagesRadioGroup>

              <PackageNote>{t('tariffs.packNote')}</PackageNote>

              <Button text={t('tariffs.proceed')} className='white1' />
            </TariffFooter>
          </TariffCard>
        </TariffsWrapper>
      </BoxTariff>
    </Container>
  );
};

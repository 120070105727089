import React from 'react';
import { Button } from 'components/common/Button';
import Container from 'index.style';
import { default as Lightbulb } from 'assets/img/Lightbulb.svg';
import {
  Card,
  CardDescription,
  CardDown,
  CardsList,
  CardsWrapper,
  CardTitle,
  Example,
  GuideSection,
  GuideText,
  PresentBox,
  Title,
} from './PresentProjects.style';
import ArrowBlue from 'assets/img/ArrowBlue.png';
import { useTranslation } from 'react-i18next';
import { useModal } from 'context/ModalContext';

interface CardData {
  title: string;
  description: string;
  example: string;
  isDown?: boolean; // Указывает, является ли карточка нижней
}

interface PresentProjectsProps {
  title?: string; // Заголовок секции
  cards: CardData[]; // Массив данных для карточек
  guideText?: string; // Текст в блоке "Руководство"
  guideButtonText?: string; // Текст кнопки в блоке "Руководство"
}

export const PresentProjects: React.FC<PresentProjectsProps> = ({ title, cards }) => {
  const { openModal } = useModal();

  const { t } = useTranslation();

  return (
    <Container>
      <PresentBox>
        <Title>{title}</Title>
        <CardsWrapper>
          {cards
            .filter((card) => !card.isDown) // Отображаем только верхние карточки
            .map((card, index) => (
              <Card key={index}>
                <CardTitle>
                  <img src={Lightbulb} alt='icon' />
                  {card.title}
                </CardTitle>
                <CardDescription>{card.description}</CardDescription>
                <Example>
                  <strong>{t('cardsDataBroker1.text3')}:</strong> {card.example}
                </Example>
              </Card>
            ))}
        </CardsWrapper>
        <CardsList>
          {cards
            .filter((card) => card.isDown) // Отображаем только нижние карточки
            .map((card, index) => (
              <CardDown key={index}>
                <CardTitle>
                  <img src={Lightbulb} alt='icon' />
                  {card.title}
                </CardTitle>
                <CardDescription>{card.description}</CardDescription>
                <Example>
                  <strong>{t('cardsDataBroker1.text3')}:</strong> {card.example}
                </Example>
              </CardDown>
            ))}
          <GuideSection>
            <img src={ArrowBlue} alt='iconArrow' />
            <GuideText>
              <span>{t('cardsDataBroker1.text1')}</span>
              {t('cardsDataBroker1.text4')}
            </GuideText>
            <Button
              text={t('cardsDataBroker1.text2')}
              className='white1'
              onClick={() => openModal(2)}
            />
          </GuideSection>
        </CardsList>
      </PresentBox>
    </Container>
  );
};

export default PresentProjects;

import { RiskAgreementBlock } from 'components/RiskAgreementBlock';
import React from 'react';

const RiskAgreementScreen = () => {
  return (
    <>
      <RiskAgreementBlock />
    </>
  );
};

export default RiskAgreementScreen;

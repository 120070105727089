import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ModalContextType {
  isOpen: boolean;
  step: number;
  openModal: (initialStep?: number) => void;
  closeModal: () => void;
  setStep: (step: number) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);

  const openModal = (initialStep: number = 1) => {
    setStep(initialStep);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    // Если хотим при закрытии сбрасывать шаг на 1 — раскомментировать:
    // setStep(1);
  };

  return (
    <ModalContext.Provider value={{ isOpen, step, openModal, closeModal, setStep }}>
      {children}
    </ModalContext.Provider>
  );
};

// Хук для удобного использования контекста
export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

import styled from 'styled-components';
import InputMask from 'react-input-mask';
import Gifts from 'assets/img/Gifts.webp';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWindow = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px; /* Фиксированная ширина */
  height: 550px; /* Фиксированная высота */
  background: #fff; /* Для примера */
  border-radius: 10px;
  overflow: hidden; /* Обрезает содержимое, выходящее за рамки */
  
  @media (max-width: 1120px) {
    flex-direction: column;
    width: 600px;
    height: 750px;
  }
`;


export const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  float: left;
`;

export const BannerGift = styled.div`
  width: 310px;
  height: 100%; /* Полная высота */
  background: url(${Gifts}) no-repeat center center;
  background-size: cover;

  @media (max-width: 1120px) {
    width: 100%;
    height: 316px;
  }
`;

export const RightBlock = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  align-self: stretch;
  background: #181b3e;
  color: #fff;

  @media (max-width: 1120px) {
    padding: 24px;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto; /* Для прокрутки при переполнении */
  max-height: calc(100% - 150px); /* Ограничение по высоте */

  h2 {
    color: #fff;

    font-size: 28px;
    font-weight: 700;
    @media (max-width: 1120px) {
      font-size: 22px;
    }
  }
  p {
    color: #fff;

    font-size: 16px;
    font-weight: 400;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  margin-top: 24px;

  button {
    width: 100%;
  }
`;

export const Description = styled.p`
  color: #fff;

  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
`;

export const InputMaskCustomBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 4px;
  margin-top: 4px;
`;

export const InputMaskCustom = styled(InputMask)`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  background: #181b3e;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.5);
  &:focus {
    outline: none;
    border-color: #5784f7; /* Замените на нужный цвет, если требуется */
  }
`;

export const InputBox = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: 50px;
  input {
    width: 100%;
  }
  @media (max-width: 1120px) {
    margin-top: 24px;
  }
`;

import React, { useState } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import { useSpring, animated } from '@react-spring/web';
import {
  ArrowButtonsContainer,
  CardContainer,
  CardDescription,
  CardTitle,
  CarouselContainer,
  Container,
  LeftArrow,
  RightArrow,
  Title,
} from './FinancingInstruments.style';

type CardProps = {
  title: string;
  description: string;
  onClick: () => void;
};

const Card: React.FC<CardProps> = ({ title, description, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const springProps = useSpring({
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
    boxShadow: isHovered ? '0 20px 40px rgba(0, 0, 0, 0.15)' : '0 5px 15px rgba(0, 0, 0, 0.1)',
  });

  return (
    <CardContainer
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};

const LeftArrowSVG = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='0 0 6 8' fill='none'>
    <path d='M1.25 0.36377L4.75 3.86377L1.25 7.36377' stroke='white' />
  </svg>
);

const RightArrowSVG = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='0 0 6 8' fill='none'>
    <path d='M1.25 0.36377L4.75 3.86377L1.25 7.36377' stroke='white' />
  </svg>
);

type FinancingInstrumentsProps = {
  title?: string;
  cardsData: { title: string; description: string }[];
  offsetRadius?: number;
};

export const FinancingInstruments: React.FC<FinancingInstrumentsProps> = ({
  title = 'Инструменты финансирования',
  cardsData,
  offsetRadius = 2,
}) => {
  const [goToSlide, setGoToSlide] = useState(0);

  const slides = cardsData.map((card, index) => ({
    key: index.toString(),
    content: (
      <Card title={card.title} description={card.description} onClick={() => setGoToSlide(index)} />
    ),
  }));

  const goToNextSlide = () => {
    setGoToSlide((prev) => (prev + 1) % slides.length);
  };

  const goToPrevSlide = () => {
    setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <CarouselContainer>
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation={false}
          animationConfig={config.gentle}
        />
      </CarouselContainer>
      <ArrowButtonsContainer>
        <LeftArrow onClick={goToPrevSlide}>
          <LeftArrowSVG />
        </LeftArrow>
        <RightArrow onClick={goToNextSlide}>
          <RightArrowSVG />
        </RightArrow>
      </ArrowButtonsContainer>
    </Container>
  );
};

import Container from 'index.style';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxContent, BoxMain, Paragraph, TextBox, Title } from './UserAgreementBlock.style';

// 1. Описываем структуру UserAgreement:
interface UserAgreementSection {
  title: string;
  paragraphs: string[];
}

interface UserAgreement {
  title: string;
  sections: UserAgreementSection[];
}

// 2. Сам компонент
export const UserAgreementBlock: React.FC = () => {
  const { t } = useTranslation();

  // 3. Получаем «сырой» объект и приводим его к нашему интерфейсу
  const rawData = t('userAgreementBlock', { returnObjects: true });
  const userAgreement = rawData as UserAgreement;

  return (
    <Container>
      <BoxMain>
        <TextBox>
          <h3>{userAgreement.title}</h3>
        </TextBox>

        {/* Перебираем секции */}
        {userAgreement.sections.map((section: UserAgreementSection, sectionIndex: number) => (
          <BoxContent key={sectionIndex}>
            {/* Заголовок секции */}
            <Title>{section.title}</Title>

            {/* Перебираем абзацы внутри каждой секции */}
            {section.paragraphs.map((paragraph: string, paragraphIndex: number) => (
              <Paragraph key={paragraphIndex}>{paragraph}</Paragraph>
            ))}
          </BoxContent>
        ))}
      </BoxMain>
    </Container>
  );
};

import styled, { keyframes } from 'styled-components';

// Анимации
export const slideFromLeft = keyframes`
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideFromRight = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideFromBottom = keyframes`
  0% {
    opacity: 1;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Стили
export const Title = styled.h2`
  color: #292d32;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const Subtitle = styled.p`
  color: #292d32;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 30px;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const CardsGrid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;

  /* Первая карточка на всю ширину */
  & > :nth-child(1) {
    grid-column: 1 / 3;
  }
  @media (max-width: 1120px) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const Card = styled.div`
  background: #f8f9fa;
  border-radius: 6px;
  padding: 24px;
  position: relative;
  transition: box-shadow 0.3s ease;
  opacity: 0;
  animation-duration: 2.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  &.first.animate {
    animation-name: ${slideFromBottom};
  }

  &.second.animate {
    animation-name: ${slideFromLeft};
  }

  &.third.animate {
    animation-name: ${slideFromRight};
  }

  &.fourth.animate {
    animation-name: ${slideFromLeft};
  }

  &.fifth.animate {
    animation-name: ${slideFromRight};
  }

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: 12px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const CardTitle = styled.h3`
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

export const CardText = styled.p`
  color: #686f84;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

export const ContainerMission = styled.div`
  margin-bottom: 100px;
`;

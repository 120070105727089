import styled from 'styled-components';
import colors from 'global_styling/colors';
import { Link } from 'react-router-dom';
import { ReactComponent as IconArrow } from 'assets/img/IconArrow.svg';

const Menu = styled.menu`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 64px;
  height: 44px;
  justify-content: space-between;
  text-decoration: none;
  @media (max-width: 1120px) {
    margin-top: 50px;
  }

  .current {
    color: ${colors.TEAL};
  }
  @media (max-width: 670px) {
    display: flex;
    grid-template-columns: 18fr 4fr 2fr;
    /* grid-template-columns: 48fr 0fr 0fr; */
    gap: 10px;
  }
  ul {
    list-style-type: none; /* Убирает маркеры */
  }
  li {
    cursor: pointer;
  }
  .menu {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    gap: 24px;
  }
  .mobile_btn {
    display: none;
  }
  @media (max-width: 1120px) {
    .mobile_btn {
      display: block;
      position: absolute;
      right: 20px;
      z-index: 10;
      cursor: pointer;
      @media (max-width: 670px) {
        position: relative;
        right: 0;
      }
    }
    .menu {
      display: none;
      flex-direction: column;
      position: fixed;
      right: -100%;
      top: 130px;
      width: 100%;
      height: calc(100% - 100px);
      z-index: 10;
      transition: right 0.5s;
      gap: 24px;
      overflow: auto;
      padding: 15px;
      visibility: visible;
      background-color: #fff;
      text-align: start;
      @media (max-width: 670px) {
        padding: 0px 16px;
        gap: 24px;
      }
    }
    .active {
      right: 0;
      display: flex;
    }
  }
`;

const LogoCompany = styled.img`
  width: 76px;
  height: 30px;
  cursor: pointer;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  .buttons {
    @media (max-width: 1120px) {
      margin-right: 100px;
    }
    @media (max-width: 670px) {
      display: none;
    }
  }
`;

const BoxSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  .buttons {
    @media (max-width: 1120px) {
      margin-right: 100px;
    }
    @media (max-width: 670px) {
      /* display: none; */
      margin-right: 0px;
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  z-index: 999;
  border-radius: 6px;
  background: #f8f9fd;
  gap: 24px;
  min-width: 258px;
  padding: 16px;
  margin-top: 10px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  cursor: pointer;
  li {
  }
  &.open {
    display: block;
  }
  @media (max-width: 1120px) {
    position: relative;
    box-shadow: none;
    background: #f8f9fd;
    width: 100%;
    padding: 24px 0px;
    li {
      width: 100%;
      gap: 24px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.CHARCOAL};
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  @media (max-width: 1120px) {
    font-size: 24px;
  }

  &:hover {
    color: ${colors.TEAL};
  }
`;

const Arrow = styled(IconArrow)<{ 'data-is-open': boolean }>`
  display: inline-block;
  transform: ${(props) => (props['data-is-open'] ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  margin-left: 6px;
  @media (max-width: 1120px) {
    width: 20px;
    height: 20px;
    margin-bottom: -2px;
    margin-left: 10px;
  }
`;

export const BoxMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export {
  Menu,
  LogoCompany,
  ButtonBox,
  DropdownContainer,
  BoxSwitcher,
  DropdownContent,
  StyledLink,
  Arrow,
};

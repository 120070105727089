import React from 'react';
import { Icon, Item, Line, Title, Container } from './CreepingLine.style';

interface CreepingLineProps {
  items: { text: string; icon: string }[]; // Массив текстов и иконок
  title?: string; // Заголовок
}

export const CreepingLine: React.FC<CreepingLineProps> = ({
  items,
  title = 'Кому подходит?', // Значение по умолчанию
}) => {
  // Дублируем элементы для бесшовного эффекта
  const infiniteItems = [...items, ...items];

  return (
    <Container>
      <Title>{title}</Title>

      {/* Верхняя строка - влево */}
      <Line direction='left'>
        {infiniteItems.map((item, index) => (
          <Item key={`top-${index}`}>
            <Icon src={item.icon} alt={`${item.text} icon`} />
            {item.text}
          </Item>
        ))}
      </Line>

      {/* Нижняя строка - вправо */}
      <Line direction='right'>
        {infiniteItems.map((item, index) => (
          <Item key={`bottom-${index}`}>
            <Icon src={item.icon} alt={`${item.text} icon`} />
            {item.text}
          </Item>
        ))}
      </Line>
    </Container>
  );
};

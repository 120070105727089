import React, { useState } from 'react';
import FAQIcon from 'assets/img/FAQIcon.svg';
import Container from 'index.style';
import {
  Answer,
  FAQContainer,
  FAQItem,
  FAQTitle,
  Icon,
  Question,
  QuestionRow,
  StyledArrow,
} from './FAQBlock.style';

const ArrowIcon: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <StyledArrow isOpen={isOpen}>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <rect y='0.36377' width='24' height='24' rx='12' fill='#F0F3FF' />
      <path
        d='M16 14.3638L12 10.3638L8 14.3638'
        stroke='#5784F7'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </StyledArrow>
);

interface FAQItemType {
  question: string;
  answer: React.ReactNode;
}

interface FAQBlockProps {
  faqData: FAQItemType[]; // Массив вопросов и ответов
  title?: string; // Заголовок секции
}

export const FAQBlock: React.FC<FAQBlockProps> = ({
  faqData,
  title = 'Часто задаваемые вопросы',
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Container>
      <FAQContainer>
        <FAQTitle>{title}</FAQTitle>
        {faqData.map((faq, index) => (
          <FAQItem key={index}>
            <QuestionRow onClick={() => toggleFAQ(index)}>
              <Icon>
                <img src={FAQIcon} alt='icon' />
              </Icon>
              <Question>{faq.question}</Question>
              <ArrowIcon isOpen={openIndex === index} />
            </QuestionRow>
            <Answer isOpen={openIndex === index}>{faq.answer}</Answer>
          </FAQItem>
        ))}
      </FAQContainer>
    </Container>
  );
};

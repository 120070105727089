// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden; /* предотвращаем горизонтальный скролл */
}

html {
  background: #fff;
}

body {
  font-family: 'PT Sans', serif;
  position: relative; /* создаём контекст для псевдоэлементов */
  z-index: 0;
  background: transparent; /* делаем фон body прозрачным, чтобы сквозь него был виден блюр */
}

body::before,
body::after {
  content: '';
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 380px;
  height: 380px;
  border-radius: 50%;
  background: rgba(87, 132, 247, 0.25);
  filter: blur(175px);
  pointer-events: none;
  z-index: -1; /* Чтобы они были позади содержимого body */
}

body::before {
  left: -150px; /* Сдвиг влево */
}

body::after {
  right: -420px; /* Сдвиг вправо */
}

/* Остальные стили */
code {
  font-family: 'PT Sans', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,kBAAkB,EAAE,wCAAwC;AAC9D;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB,EAAE,yCAAyC;EAC7D,UAAU;EACV,uBAAuB,EAAE,iEAAiE;AAC5F;;AAEA;;EAEE,WAAW;EACX,eAAe;EACf,QAAQ;EACR,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,oCAAoC;EACpC,mBAAmB;EACnB,oBAAoB;EACpB,WAAW,EAAE,2CAA2C;AAC1D;;AAEA;EACE,YAAY,EAAE,gBAAgB;AAChC;;AAEA;EACE,aAAa,EAAE,iBAAiB;AAClC;;AAEA,oBAAoB;AACpB;EACE,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ","sourcesContent":["html,\nbody {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  min-height: 100%;\n  overflow-x: hidden; /* предотвращаем горизонтальный скролл */\n}\n\nhtml {\n  background: #fff;\n}\n\nbody {\n  font-family: 'PT Sans', serif;\n  position: relative; /* создаём контекст для псевдоэлементов */\n  z-index: 0;\n  background: transparent; /* делаем фон body прозрачным, чтобы сквозь него был виден блюр */\n}\n\nbody::before,\nbody::after {\n  content: '';\n  position: fixed;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 380px;\n  height: 380px;\n  border-radius: 50%;\n  background: rgba(87, 132, 247, 0.25);\n  filter: blur(175px);\n  pointer-events: none;\n  z-index: -1; /* Чтобы они были позади содержимого body */\n}\n\nbody::before {\n  left: -150px; /* Сдвиг влево */\n}\n\nbody::after {\n  right: -420px; /* Сдвиг вправо */\n}\n\n/* Остальные стили */\ncode {\n  font-family: 'PT Sans', serif;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

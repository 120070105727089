import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 28px;
  color: #292d32;

  text-align: center;
  font-size: 32px;
  font-weight: 700;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #292d32;
  font-weight: 600;
`;

const Paragraph = styled.p`
  margin-top: 12px;
  color: #686f84;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const PrivacyPolicyLink = styled.a`
  color: #5784f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;

  &:hover {
    color: #5784f7;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 10px;
`;

export { BoxMain, TextBox, Title, Paragraph, PrivacyPolicyLink, BoxContent };

import styled from 'styled-components';

export const Title = styled.h1`
  color: #292d32;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const PresentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 150px 0px;
  @media (max-width: 1120px) {
    padding: 0px 0px 50px 0px;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  background: #f8f9fd;
  border-radius: 6px;
  padding: 24px;
  width: 100%;
`;

export const CardTitle = styled.div`
  color: #5784f7;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: 1120px) {
    font-size: 16px;
  }
`;

export const CardDescription = styled.p`
  color: #808185;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;

export const Example = styled.div`
  margin-top: 24px;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: rgba(87, 132, 247, 0.05);

  color: #686f84;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

export const GuideSection = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  background: #5784f7;
  width: 50%;

  button {
    margin-top: 24px;
  }

  img {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1120px) {
    width: 100%;
  }
`;

export const GuideText = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1120px) {
    font-size: 14px;
  }
  span {
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 1120px) {
      font-size: 16px;
    }
  }
`;

export const CardsList = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

export const CardDown = styled.div`
  background: #f8f9fd;
  border-radius: 6px;
  padding: 24px;
  width: 50%;
  @media (max-width: 1120px) {
    width: 100%;
  }
`;

import React, { useState } from 'react';
import axios from 'axios';
import {
  BannerGift,
  ButtonBox,
  CloseButton,
  Description,
  InputBox,
  ModalOverlay,
  ModalWindow,
  RightBlock,
  TextBlock,
  InputMaskCustom,
  InputMaskCustomBox,
} from './ModalGifts.style';
import { useModal } from 'context/ModalContext';
import { Button } from 'components/common/Button';
import { ErrorMessage } from 'components/FeedbackBlock/FeedbackBlock.style';
import { Input } from 'components/common/Input';
import { isValidPhone } from 'utils/isValidPhone';
import { useTranslation } from 'react-i18next';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Функция для отправки формы на нужный эндпоинт
async function sendGiftApi(payload: { email: string; phone: string }) {
  return await axios.post('https://lend.kz/api/v1/landing/send/gift', payload, {
    withCredentials: true,
  });
}

export const ModalGifts: React.FC = () => {
  const { t } = useTranslation(); // Функция для получения переводов

  const { isOpen, step, closeModal, setStep } = useModal();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [inputErrors, setInputErrors] = useState({
    phoneError: '',
    emailError: '',
  });

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
    const isPhoneValidValue = isValidPhone(value);
    setInputErrors((prev) => ({
      ...prev,
      phoneError: isPhoneValidValue ? '' : t('feedbackBlock.errors.invalidFormat'),
    }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    const isEmailValidValue = isValidEmail(value);
    setInputErrors((prev) => ({
      ...prev,
      emailError: isEmailValidValue ? '' : t('feedbackBlock.errors.invalidFormat'),
    }));
  };

  // Функция отправки на кнопку «Получить бесплатное руководство на почту»
  const handleGetGift = async () => {
    const validPhone = isValidPhone(phone);
    const validEmail = isValidEmail(email);

    setInputErrors({
      phoneError: validPhone ? '' : t('feedbackBlock.errors.invalidFormat'),
      emailError: validEmail ? '' : t('feedbackBlock.errors.invalidFormat'),
    });

    if (validPhone && validEmail) {
      try {
        const response = await sendGiftApi({ email, phone });
        if (response.status === 200) {
          setStep(3);
        } else {
          console.log(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while sending the form:', error);
      }
    }
  };

  if (!isOpen) return null;

  const renderStep1 = () => (
    <>
      <BannerGift />

      <RightBlock>
        <CloseButton onClick={closeModal}>
          {/* SVG-крестик */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'>
            <path
              d='M12.0007 11.0865L16.9504 6.13672L18.3646 7.55093L13.4149 12.5007L18.3646 17.4504L16.9504 18.8646L12.0007 13.9149L7.05093 18.8646L5.63672 17.4504L10.5865 12.5007L5.63672 7.55093L7.05093 6.13672L12.0007 11.0865Z'
              fill='#5784F7'
            />
          </svg>
        </CloseButton>

        <TextBlock>
          <h2>{t('modalGifts.step1.title')}</h2>
          <p>{t('modalGifts.step1.paragraph')}</p>
          <Description>{t('modalGifts.step1.question')}</Description>
        </TextBlock>

        <ButtonBox>
          <Button
            text={t('modalGifts.step1.investorButton')}
            onClick={() => {
              setStep(2);
            }}
          />
          <Button
            text={t('modalGifts.step1.borrowerButton')}
            onClick={() => {
              setStep(3);
            }}
          />
        </ButtonBox>
      </RightBlock>
    </>
  );

  const renderStep2 = () => (
    <>
      <BannerGift />

      <RightBlock>
        <CloseButton onClick={closeModal}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'>
            <path
              d='M12.0007 11.0865L16.9504 6.13672L18.3646 7.55093L13.4149 12.5007L18.3646 17.4504L16.9504 18.8646L12.0007 13.9149L7.05093 18.8646L5.63672 17.4504L10.5865 12.5007L5.63672 7.55093L7.05093 6.13672L12.0007 11.0865Z'
              fill='#5784F7'
            />
          </svg>
        </CloseButton>

        <TextBlock>
          <h2>{t('modalGifts.step2.title')}</h2>
          <ul>
            <li>{t('modalGifts.step2.bullet1')}</li>
            <li>{t('modalGifts.step2.bullet2')}</li>
            <li>{t('modalGifts.step2.bullet3')}</li>
            <li>{t('modalGifts.step2.bullet4')}</li>
          </ul>
        </TextBlock>

        <InputBox>
          <Input
            type='email'
            value={email}
            placeholder={t('feedbackBlock.form.placeholderEmail') || ''}
            onChange={handleEmailChange}
            error={inputErrors.emailError}
            className='white'
          />
          <InputMaskCustomBox>
            <InputMaskCustom
              mask='+7 (999) 999-99-99'
              maskChar={null}
              value={phone}
              placeholder={t('feedbackBlock.form.placeholderPhone') || ''}
              onChange={handlePhoneChange}
              className={inputErrors.phoneError ? 'input-error' : ''}
              autoComplete='off'
            />
            {inputErrors.phoneError && <ErrorMessage>{inputErrors.phoneError}</ErrorMessage>}
          </InputMaskCustomBox>
        </InputBox>

        <ButtonBox>
          <Button text={t('modalGifts.step2.buttonGetGift')} onClick={handleGetGift} />
        </ButtonBox>
      </RightBlock>
    </>
  );

  const renderStep3 = () => (
    <>
      <BannerGift />

      <RightBlock>
        <CloseButton onClick={closeModal}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'>
            <path
              d='M12.0007 11.0865L16.9504 6.13672L18.3646 7.55093L13.4149 12.5007L18.3646 17.4504L16.9504 18.8646L12.0007 13.9149L7.05093 18.8646L5.63672 17.4504L10.5865 12.5007L5.63672 7.55093L7.05093 6.13672L12.0007 11.0865Z'
              fill='#5784F7'
            />
          </svg>
        </CloseButton>

        <TextBlock>
          <h2>{t('modalGifts.step3.title')}</h2>
          <p>{t('modalGifts.step3.paragraph')}</p>
        </TextBlock>
      </RightBlock>
    </>
  );

  return (
    <ModalOverlay>
      <ModalWindow>
        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
        {step === 3 && renderStep3()}
      </ModalWindow>
    </ModalOverlay>
  );
};

import { Tariff } from 'components/Tariff';
import React from 'react';

const TariffScreen = () => {
  return (
    <>
      <Tariff />
    </>
  );
};

export default TariffScreen;

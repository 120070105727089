import React from 'react';
import Container from 'index.style';
import { default as ArrowProfit } from 'assets/img/ArrowProfit.svg';
import {
  Avatar,
  AvatarsWrapper,
  Card,
  CardDescription,
  CardTitle,
  DetailBox,
  DetailBoxProfit,
  DetailItem,
  DetailLabel,
  Details,
  DetailValue,
  Grid,
  Header,
  Name,
  PersonInfo,
  Profit,
  ProfitValue,
  Role,
  Title,
  Wrapper,
} from './SuccessStories.style';
import { useTranslation } from 'react-i18next';

interface Story {
  avatars?: string[];
  avatar?: string;
  name: string;
  role: string;
  title: React.ReactNode;
  description: React.ReactNode;
  details: {
    purpose: string;
    amount: string;
    term: string;
    profitLabel?: string;
    profitValue?: string;
    form: string;
  };
}

interface SuccessStoriesProps {
  stories: Story[]; // Пропсы для передачи массива историй
  sectionTitle?: string; // Заголовок блока
}

export const SuccessStories: React.FC<SuccessStoriesProps> = ({ stories, sectionTitle }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Title>{sectionTitle}</Title>
        <Grid>
          {stories.map((story, index) => (
            <Card key={index}>
              <Header>
                {story.avatars ? (
                  <AvatarsWrapper>
                    {story.avatars.map((src, i) => (
                      <Avatar key={i} src={src} alt={`avatar-${i}`} />
                    ))}
                  </AvatarsWrapper>
                ) : (
                  <Avatar src={story.avatar} alt='avatar' />
                )}
                <PersonInfo>
                  <Name>{story.name}</Name>
                  <Role>{story.role}</Role>
                </PersonInfo>
              </Header>
              <CardTitle>{story.title}</CardTitle>
              <CardDescription>{story.description}</CardDescription>
              <Details>
                <DetailBox>
                  <DetailItem>
                    <DetailLabel>{t('storiesData.amount')}</DetailLabel>
                    <DetailValue>{story.details.amount}</DetailValue>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>{t('storiesData.term')}</DetailLabel>
                    <DetailValue>{story.details.term}</DetailValue>
                  </DetailItem>
                </DetailBox>
                <DetailBox>
                  <DetailItem>
                    <DetailLabel>{t('storiesData.purpose')}</DetailLabel>
                    <DetailValue>{story.details.purpose}</DetailValue>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>{t('storiesData.form')}</DetailLabel>
                    <DetailValue>{story.details.form}</DetailValue>
                  </DetailItem>
                </DetailBox>
                {story.details.profitLabel && story.details.profitValue && (
                  <DetailBoxProfit>
                    <Profit>
                      <span>
                        <img src={ArrowProfit} alt='iconProfit' />
                        {story.details.profitLabel}
                      </span>
                      <ProfitValue>{story.details.profitValue}</ProfitValue>
                    </Profit>
                  </DetailBoxProfit>
                )}
              </Details>
            </Card>
          ))}
        </Grid>
      </Wrapper>
    </Container>
  );
};
